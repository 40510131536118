import Header from './Header.js';
import Footer from './Footer.js';

import { links } from './data/links.js';
import { images } from './data/images.js';

import './App.css';

function App() {
  return (
    <div className="App">
      <Header />

      <h1>Alex Tamura</h1>
      <h2>That is my name</h2>
      <h3>My middle name is Shinichi</h3>
      <h4>But you don't need to know that</h4>
      <h5>Or maybe you do</h5>
      <h6>In that case, there you go</h6>
      <hr />

      <h1>Links</h1>
      {
        links.map((link, i) =>
          <p key={`link-${i}`}><a href={link.url} target="_blank" rel="noreferrer">{link.name}</a></p>
        )
      }
      <hr />

      <h1>Images</h1>
      {
        images.map((image, i) =>
          <div className="image-div" key={`image-${i}`}>
            {
              image.url ?
              <>
                <p>{image.title}</p>
                <img src={image.url} alt={image.title} />
              </> :
              <>
                <p>{image.title}</p>
                <img src={require('./images/' + image.name).default} alt={image.title} />
              </>
            }
          </div>
        )
      }

      <Footer />
    </div>
  );
}

export default App;
