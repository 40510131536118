import './Footer.css';

function Footer() {
  return (
    <div className="Footer">
      <p>© Alex Tamura {new Date().getFullYear()}</p>
    </div>
  );
}

export default Footer;
