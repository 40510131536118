export const images = [
  {
    title: "Wholesome",
    url: "https://pbs.twimg.com/media/E2bYKiHVoAIKRF8?format=jpg&name=240x240"
  },
  {
    title: "Funny",
    name: "funny.jpg"
  }
];
