export const links = [
  {
    name: "GitHub",
    url: "https://github.com/alextamura"
  },
  {
    name: "Twitter",
    url: "https://twitter.com/lextamura"
  },
  {
    name: "Floppa Chungus",
    url: "https://www.reddit.com/r/bigfloppa/comments/gwna4q/flop_chungus/"
  },
  {
    name: "Chadwick",
    url: "https://chadwickschool.org"
  },
  {
    name: "Le Reddit",
    url: "https://www.reddit.com"
  }
]
