import './Header.css';

function Header() {
  return (
    <div className="Header">
      <p>Alex Tamura</p>
    </div>
  );
}

export default Header;
